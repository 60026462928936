import React from 'react';
import { Flex, Box } from 'reflexbox';
import { Form } from './contactForm';
import styled from '../../config/theme';
import map from '../../assets/icons/map.svg';
import card from '../../assets/icons/card.svg';
import phone from '../../assets/icons/phone.svg';
import email from '../../assets/icons/email.svg';
import { FormattedMessage as Tr } from 'react-intl';

const Contact = () => (
  <ContactContainer>
    <ContactPageWrapper flexWrap="wrap">
      <Box width={[1, 2 / 3]} p={3}>
        <Form />
      </Box>
      <InfoWrapper width={[1, 1 / 3]} p={3}>
        <H3tag>
          <Tr id="contactForm.myInformation" />
        </H3tag>
        <br />
        <Icon src={email} />
        <Ptag>
          <MailTo href="mailto:kvc@four-c.be">kvc@four-c.be</MailTo>
        </Ptag>
        <Icon src={phone} />
        <Ptag>+32 475 47 03 98</Ptag>
        <Icon src={map} />
        <Ptag>
          Merellaan 11
          <br />
          2950 Kapellen
          <br />
          <Tr id="contactForm.belgium" />
        </Ptag>
        <Icon src={card} />
        <Ptag>BE0666591918</Ptag>
      </InfoWrapper>
    </ContactPageWrapper>
  </ContactContainer>
);

export default Contact;

export const MailTo = styled.a`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;

export const InfoWrapper = styled(Box)`
  align-self: center;
`;

const ContactContainer = styled.div`
  margin-top: -80px;
`;

const H3tag = styled.h3`
  padding-left: 40px;
  font-weight: 700;
  letter-spacing: 1px;
`;

const Icon = styled.img`
  display: inline;
  position: absolute;
  padding: 10px 0 10px 0;
`;

const Ptag = styled.p`
  padding: 6px 0 6px 40px;
  display: flex;
`;

const ContactPageWrapper = styled(Flex)`
  margin-top: 50px;
`;
