import { changeLocale, IntlContextConsumer } from 'gatsby-plugin-intl';
import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import Nl from '../../assets/icons/languages/be.svg';
import En from '../../assets/icons/languages/uk.svg';
import De from '../../assets/icons/languages/de.svg';

const languageOptions = [
  {
    value: 'en',
    label: <img src={En} alt="en" width="30px" />,
  },
  {
    value: 'nl',
    label: <img src={Nl} alt="nl" width="30px" />,
  },
  {
    value: 'de',
    label: <img src={De} alt="de" width="30px" />,
  },
];

const LanguageSwitcher = () => {
  const handleChangeLang = (chosenLang) => {
    changeLocale(chosenLang.value);
  };

  return (
    <React.Fragment>
      <IntlContextConsumer>
        {(currentLocale) => (
          <span>
            <StyledSelect
              options={languageOptions}
              value={languageOptions.filter((languageOption) => languageOption.value === currentLocale.language)}
              onChange={handleChangeLang}
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </span>
        )}
      </IntlContextConsumer>
    </React.Fragment>
  );
};

export default LanguageSwitcher;

const StyledSelect = styled(Select)`
  border-radius: 6px;
  color: black;
  display: inline-block;
  font-size: 1rem;
  max-height: 50px;
  width: 80px;
  z-index: 99;
  img {
    border-radius: 50%;
    padding: 6px;
  }
`;
