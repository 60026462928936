import * as React from 'react';
import styled from 'styled-components';
import ClientSlider from './ClientSlider';
import { media } from '../../config/theme/utils';
import { FormattedMessage as Tr } from 'react-intl';

const Clients = () => (
  <Wrapper>
    <Title>
      <Tr id="satisfied" />
    </Title>
    <ClientSlider />
  </Wrapper>
);

const Wrapper = styled.section`
  align-items: center;
  margin: auto;
  max-width: 1200px;
  padding: 150px 0;

  ${media.tabletAndBelow`
    padding: 40px 2px 10px 2px;
  `}
`;

const Title = styled.h2`
  text-align: center;
  font-size: 40px;
  padding-bottom: 20px;
`;

export default Clients;
