import axios from 'axios';
import { Flex } from 'reflexbox';
import { FormElement, FormInput, FormTextArea, FormWrapper } from './';
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage as Tr } from 'react-intl';

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const Form = () => {
  const [formValues, setFormValues] = React.useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const { name, email, phone, message } = formValues;
  const [sent, setSent] = React.useState(false);

  const handleSubmit = (e) => {
    console.log('Submitting form.');
    this.setState({ isSending: true });
    axios
      .post('/', encode({ 'form-name': 'contact-upshift', ...this.state }), {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then(() => {
        setSent(true);
      })
      .catch(function (error) {
        console.log('Error while sending: ' + error);
      });
    this.setState({ isSending: false });
    e.preventDefault();
  };

  const handleChange = (e) => setFormValues({ ...formValues, [e.target.name]: e.target.value });

  return (
    <Flex justifyContent={'center'}>
      <FormWrapper p={[3, 3, 4]} pt={[3]}>
        <form name="contact-upshift" data-netlify="true" onSubmit={handleSubmit}>
          <input type="hidden" name="form-name" value="contact-upshift" />
          <FormElement>
            <Tr id="contactForm.name" />
            <FormInput type="text" name="name" value={name} onChange={handleChange} />
          </FormElement>
          <FormElement>
            <Tr id="contactForm.email" />
            <FormInput type="email" name="email" value={email} onChange={handleChange} />
          </FormElement>
          <FormElement>
            <Tr id="contactForm.phone" />
            <FormInput type="tel" name="phone" value={phone} onChange={handleChange} />
          </FormElement>
          <FormElement>
            <Tr id="contactForm.message" />
            <FormTextArea rows="4" name="message" value={message} onChange={handleChange} />
          </FormElement>
          <Flex justifyContent="flex-end">
            <Button type="submit">
              <Tr id="contactForm.sendBtn" />
            </Button>
          </Flex>
          <Flex justifyContent="flex-end">
            {sent && (
              <small>
                <Tr id="contactForm.messageSent" />
              </small>
            )}
          </Flex>
        </form>
      </FormWrapper>
    </Flex>
  );
};

const Button = styled.button`
  background: ${(props) => props.theme.palette.primary.shade4};
  border-radius: 5px;
  border: 0px solid;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  transition: all 0.2s ease-in-out;
  width: 100%;

  &:hover {
    box-shadow: ${(props) => props.theme.general.boxShadow.base};
    cursor: pointer;
    transform: translateY(-1px);
  }
`;

export default Form;
