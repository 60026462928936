import React from 'react';
import styled from '../../config/theme';
import { FormattedMessage as Tr } from 'react-intl';

const AboutMe = () => (
  <Container>
    <Wrapper>
      <Tr id={'aboutMe.one'} />
      <b>
        <Tr id={'aboutMe.two'} />{' '}
      </b>
      <Tr id={'aboutMe.three'} />
      <br />
      <Tr id={'aboutMe.four'} />
      <b>
        <Tr id={'aboutMe.five'} />
      </b>
      <Tr id={'aboutMe.six'} />
      <br />
      <Tr id={'aboutMe.seven'} />
      <b>
        <Tr id={'aboutMe.eight'} />
      </b>
      <Tr id={'aboutMe.nine'} />
      <b>
        <Tr id={'aboutMe.ten'} />{' '}
      </b>
      <Tr id={'aboutMe.eleven'} />
      <b>
        <Tr id={'aboutMe.twelve'} />
      </b>
      <Tr id={'aboutMe.thirteen'} />
    </Wrapper>
  </Container>
);

const Container = styled.div`
  background: rgba(0, 174, 150, 0.2);
  padding: 40px 0 100px 0;
`;
const Wrapper = styled.div`
  padding: 40px;
  max-width: 1000px;
  margin: auto;
`;

export default AboutMe;
