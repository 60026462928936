import * as React from 'react';
import { FormattedMessage as Tr } from 'react-intl';
import styled from 'styled-components';
import { media } from '../../config/theme/utils';

const ServiceBox = ({ iconSrc, text, marginUp }) => (
  <BoxWrapper marginUp={marginUp}>
    <IconWrapper>
      <Icon src={iconSrc} />
    </IconWrapper>
    <BoxTitle>
      <Tr id={`services.${text}`} />
    </BoxTitle>
  </BoxWrapper>
);

const BoxTitle = styled.p`
  margin-top: 40px;
  text-align: center;
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  max-width: 30px;
`;

const IconWrapper = styled.span`
  background: rgba(178, 247, 237, 0.5);
  border-radius: 50px;
  display: flex;
  height: 65px;
  justify-content: center;
  padding: 15px;
  width: 65px;
`;

const BoxWrapper = styled.div`
  align-items: center;
  background: white;
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.general.boxShadow.base};
  display: flex;
  flex-direction: column;
  height: 220px;
  padding: 20px;
  width: 190px;
  margin: 0 10px 40px 40px;

  ${media.tablet`
    width: 170px;
    margin: 0 10px 20px 20px;
    height: 200px;
  `};
  ${media.mobile`
    margin: 0 8px 20px;
    width: 160px;
    height: 190px;
  `};
`;

export default ServiceBox;
