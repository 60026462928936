import React from 'react';
import Slider from 'react-slick';
import { Box, Flex } from 'reflexbox';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import asona from '../../assets/clients/asona.png';
import ava from '../../assets/clients/ava.png';
import avc from '../../assets/clients/avc.png';
import caran from '../../assets/clients/caran.png';
import marabu from '../../assets/clients/marabu.png';
import schmincke from '../../assets/clients/schmincke.png';
import { media } from '../../config/theme/utils';
import '../../styles/slick.css';

// export default class SimpleSlider extends Component {
const ClientSlider = () => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 2500,
    dots: true,
    infinite: true,
    lazyLoad: true,
    slidesToScroll: 4,
    slidesToShow: 4,
    speed: 800,
    responsive: [
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <ClientsWrapper>
      <Wrapper>
        <ThemesWrapper>
          <Slider {...settings}>
            <Theme>
              <InnerTheme>
                <AuthorImage src={asona} alt="asona" />
              </InnerTheme>
            </Theme>
            <Theme>
              <InnerTheme>
                <AuthorImage src={ava} alt="ava" />
              </InnerTheme>
            </Theme>
            <Theme>
              <InnerTheme>
                <AuthorImage src={avc} alt="avc" />
              </InnerTheme>
            </Theme>
            <Theme>
              <InnerTheme>
                <AuthorImage src={caran} alt="caran d'ache" />
              </InnerTheme>
            </Theme>
            <Theme>
              <InnerTheme>
                <AuthorImage src={schmincke} alt="schmincke" />
              </InnerTheme>
            </Theme>
            <Theme>
              <InnerTheme>
                <AuthorImage src={marabu} alt="marabu" />
              </InnerTheme>
            </Theme>
          </Slider>
        </ThemesWrapper>
      </Wrapper>
    </ClientsWrapper>
  );
};

const ClientsWrapper = styled.div`
  ${media.mobile`
    margin: 50px 0;
  `}
  ${media.tablet`
    margin: 50px 0;
  `}
  position: relative;
`;

const ClientsAnchor = styled.div`
  position: absolute;
  top: -80px;
`;

const Wrapper = styled.div``;
const ThemesWrapper = styled.div`
  max-width: 1800px;
  margin: 0 auto;
`;

const Theme = styled(Box)`
  position: relative;
  min-height: 200px;
  max-width: 280px;
  padding: 0 10px;
  align-self: center;
`;

const InnerTheme = styled(Box)`
  position: absolute;
  padding: 10px 10px;
  height: 100%;
  width: 95%;
  border-radius: 10px;
  text-align: left;
  height: 100%;
  vertical-align: middle;
`;

const AuthorImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export default ClientSlider;
