import React from 'react';
import koen_img from '../../assets/images/koen_comp.jpg';
import styled from 'styled-components';
import { media } from '../../config/theme/utils';

const KoenImg = () => (
  <Wrapper>
    <Background />
    <CustomImg src={koen_img} />
  </Wrapper>
);

const Wrapper = styled.div`
  ${media.mobile`
  display: none;
  `}
`;
const CustomImg = styled.img`
  border-radius: 8px;
  display: inline-block;
  height: 200px;
  z-index: 99;

  ${media.tablet`
    height: 160px;
    width: 160px;
  `}
  ${media.mobile`
    height: 110px;
    width: 110px;
  `}
`;

const Background = styled.div`
  background: ${(props) => props.theme.palette.primary.shade4};
  z-index: -1;
  border-radius: 8px;
  height: 200px;
  position: absolute;
  width: 200px;
  margin: -25px 0 0 -25px;

  ${media.tablet`
  height: 160px;
  width: 160px;
  margin: -20px 0 0 -20px;
  `}
  ${media.mobile`
  height: 110px;
  width: 110px;
  margin: -13px 0 0 -13px;
  `}
`;

export default KoenImg;
