import React from 'react';
import { FormattedMessage as Tr } from 'react-intl';
import styled from 'styled-components';
import background from '../assets/images/background.jpg';
import GlobalCollection from '../config/GlobalCollection';
import { media } from '../config/theme/utils';
import AboutMe from '../modules/aboutMe/AboutMe';
import Clients from '../modules/clients/Clients';
import Contact from '../modules/contact/Contact';
import Navigation from '../modules/navigation/Navigation';
import Services from '../modules/services/Services';
import LanguageSwitcher from '../modules/shared/LanguageSwitcher';

const Index = () => {
  return (
    <GlobalCollection>
      <LanguageSwitchLocator>
        <LanguageSwitcher />
      </LanguageSwitchLocator>
      <Hero>
        <Navigation />
        <HeroText>
          <h2>
            <Tr id="title.first" />
            <br />
            <Tr id="title.second" />
            <Strong>
              <Tr id="title.c" />
            </Strong>
            's
          </h2>
        </HeroText>
      </Hero>
      <Services />
      <Clients />
      <AboutMe />
      <Contact />
    </GlobalCollection>
  );
};

const LanguageSwitchLocator = styled.div`
  position: fixed;
  top: 0;
  right: 10px;
`;

const Strong = styled.p`
  font-weight: 900;
  display: inline;
`;

const HeroText = styled.div`
  padding: 200px 0 120px;
  align-self: center;

  ${media.tabletAndBelow`
    padding: 20px 0 10px;
  `}
`;

const Hero = styled.div`
  background: url(${background}) no-repeat center right fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  text-align: center;
  color: #ffffff;
  min-height: 40vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  ${media.mobile`
    min-height: 75vh;
  `}
`;

export default Index;
